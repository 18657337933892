import { BannerCarousel, mapStrapiBanner, ThemeProvider } from '@petcircle/component-library';
import React from 'react';
import type { BannerItem } from '@components/models.ts';
import { trackEvent } from '@tracking/track-event';
import { EETransport, EEventName, EEventSource, EEventType } from '@tracking/models';
import { getLink } from '@widgets/utils';

export const BannerCarouselWrapper = ({ bannerSlideItems }: { bannerSlideItems: BannerItem[] }) => {
    const slideItems = bannerSlideItems.map((item: BannerItem) => {
        item.url_path = getLink(item.url_path);
        return item;
    });
    const mappedBannerItems = mapStrapiBanner(slideItems);

    return (
        <ThemeProvider>
            {
                (
                    <BannerCarousel
                        bannerSlideItems={mappedBannerItems}
                        onSlideClick={(urlPath: string, index: number) =>
                            trackEvent({
                                eventName: EEventName.Navigation,
                                eventOutcome: 'Homepage banner carousel clicked',
                                pageSection: 'Homepage banner carousel',
                                sectionComponent: 'banner',
                                itemNameValue: `Homepage banner carousel ${index + 1}`,
                                customOne: getLink(urlPath),
                                componentItem: 'image tile',
                                eventType: EEventType.Click,
                                eventSource: EEventSource.User,
                                transport: EETransport.Beacon,
                            })
                        }
                        onCtaButtonsClick={(urlPath, buttonIndex, slideIndex) =>
                            trackEvent({
                                eventName: EEventName.Navigation,
                                eventOutcome: 'Homepage banner carousel CTA button clicked',
                                pageSection: 'Homepage banner carousel CTA button',
                                sectionComponent: 'banner',
                                itemNameValue: `Homepage banner carousel ${slideIndex + 1} button ${buttonIndex}`,
                                customOne: getLink(urlPath),
                                componentItem: 'button',
                                eventType: EEventType.Click,
                                eventSource: EEventSource.User,
                                transport: EETransport.Beacon,
                            })
                        }
                    />
                ) as any
            }
        </ThemeProvider>
    );
};
